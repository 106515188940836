import { Space } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { useUserInfo } from '../../context/UserInfoContext';
import useClickAway from '../../hooks/useClickAway';
import useDesktop from '../../hooks/useDesktop';
import { Navigation } from '../../navigation';
import { Label } from '../../style/utils';
import { isClient, isKam, isManager, isMultiAccess } from '../../utils/user';
import InvisibleButton from '../InvisibleButton';
import MenuBurger from '../MenuBurger';
import Popover from '../Popover';
import SelectLang from '../SelectLang/SelectLang';
import { DesktopContainer, HeaderBar, Logo, Slider, WrapperLink, WrapperUserMenu } from './Header.styled';

const getLinks = (role) => {
  const linksItem = [{ label: 'dashboard', path: Navigation.DASHBOARD, exact: true }];

  if (isManager(role)) {
    linksItem.push({ label: 'kams', path: Navigation.MULTI_ACCESS });
    linksItem.push({ label: 'budget', path: Navigation.BUDGET });
    linksItem.push({ label: 'market-trend', path: Navigation.MARKET_TREND });
    linksItem.push({ label: 'help', path: Navigation.HELP });
  } else if (isKam(role)) {
    linksItem.push({ label: 'account-client', path: Navigation.MULTI_ACCESS });
    linksItem.push({ label: 'contracts', path: Navigation.CONTRACTS });
    linksItem.push({ label: 'simulation', path: Navigation.SIMULATION });
    linksItem.push({ label: 'market-trend', path: Navigation.MARKET_TREND });
    linksItem.push({ label: 'help', path: Navigation.HELP });
  } else if (isClient(role)) {
    linksItem.push({ label: 'contracts', path: Navigation.CONTRACTS });
    linksItem.push({ label: 'simulation', path: Navigation.SIMULATION });
    linksItem.push({ label: 'market-trend', path: Navigation.MARKET_TREND });
    linksItem.push({ label: 'help', path: Navigation.HELP });
  } else {
    throw new Error(`'This role: \`${role}\`' is not handled`);
  }

  return linksItem;
};

const DisplayLink = () => {
  const [t] = useTranslation();
  const isDesktop = useDesktop();
  const { userInfo } = useUserInfo();
  const links = getLinks(userInfo.role);

  return (
    <Space direction={isDesktop ? 'horizontal' : 'vertical'} size="large">
      {links.map(({ label, path, exact }) => (
        <Label key={path}>
          <NavLink activeClassName="active-link" exact={exact} to={path}>
            {t(`menu-${label}`)}
          </NavLink>
        </Label>
      ))}
    </Space>
  );
};

const DesktopNavigation = ({ userMenu }) => {
  const { userInfo } = useUserInfo();
  const themeContext = React.useContext(ThemeContext);
  const history = useHistory();

  return (
    <HeaderBar>
      <DesktopContainer>
        {!isMultiAccess(userInfo)}

        <Logo>
          <InvisibleButton onClick={() => history.push(Navigation.DASHBOARD)}>
            <img alt="Logo YEM Optimization" src={themeContext.logo.dashboard} />
          </InvisibleButton>
        </Logo>

        <WrapperLink>
          <DisplayLink />
          {userMenu}
        </WrapperLink>
      </DesktopContainer>
    </HeaderBar>
  );
};

const MobileNavigation = ({ userMenu }) => {
  const slideNavRef = useRef();
  const [showSidebar, setShowSidebar] = useClickAway(slideNavRef);
  const location = useLocation();

  React.useEffect(() => {
    setShowSidebar(false);
  }, [location, setShowSidebar]);

  return (
    <>
      <Slider ref={slideNavRef} show={showSidebar}>
        <MenuBurger isActive={showSidebar} onClick={() => setShowSidebar(!showSidebar)} />
        <Space direction="vertical" size="large">
          <DisplayLink />
          {userMenu}
        </Space>
      </Slider>
    </>
  );
};

const UserMenu = ({ isDesktop, isTimeTravelActive, setShowDemo, showDemo }) => (
  <WrapperUserMenu>
    <Space direction={isDesktop ? 'horizontal' : 'vertical'} size={isDesktop ? 'small' : 'large'}>
      <Popover isTimeTravelActive={isTimeTravelActive} setShowDemo={setShowDemo} showDemo={showDemo} />
      <SelectLang isDesktop={isDesktop} />
    </Space>
  </WrapperUserMenu>
);

const Header = ({ isTimeTravelActive, showDemo, setShowDemo }) => {
  const isDesktop = useDesktop();

  const UserMenuComponent = React.useMemo(
    () => (
      <UserMenu
        isDesktop={isDesktop}
        isTimeTravelActive={isTimeTravelActive}
        setShowDemo={setShowDemo}
        showDemo={showDemo}
      />
    ),
    [isDesktop, isTimeTravelActive, setShowDemo, showDemo],
  );

  return isDesktop ? (
    <header>
      <DesktopNavigation userMenu={UserMenuComponent} />
    </header>
  ) : (
    <MobileNavigation userMenu={UserMenuComponent} />
  );
};

export default Header;
