import { Col, Row, Space } from 'antd';
import LogRocket from 'logrocket';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router';

import Breadcrumb from '../components/Breadcrumb';
import Header from '../components/Header/Header';
import PoweredByYem from '../components/PoweredByYem/PoweredByYem';
// import LastFixation from '../components/LastFixation/LastFixation';
import ReactHelmet from '../components/ReactHelmet';
import { ScrollProvider } from '../context/ScrollContext';
import { useUserInfo } from '../context/UserInfoContext';
import { useGetContracts } from '../endpoints/contracts/useGetContracts';
import { useGetGroupList } from '../endpoints/groups/useGetGroupList';
import { useGetMarketTrends } from '../endpoints/market-trends/useGetMarketTrends';
import { useGetMultiAccessList } from '../endpoints/multiaccess/useGetMultiAccessList';
import { useGetTimeTravel } from '../endpoints/time-travel/useGetTimeTravel';
import useFreeLicenseOver from '../hooks/useFreeLicenseOver';
import useGetPortalApp from '../hooks/useGetPortalApp';
import { Navigation } from '../navigation';
import WrapperMultiAccess from '../screens/multiAccess/WrapperMultiAccess';
import { isClient, isFreemium, isManager, isMultiAccess } from '../utils/user';
import { WrapperScroll } from './AuthenticatePage.styled';

const ProductDetails = lazy(() => import('../screens/ProductDetails'));
const Fixation = lazy(() => import('../screens/Fixation'));
const BlockFixation = lazy(() => import('../screens/BlockFixation'));
const Objectives = lazy(() => import('../screens/Objectives'));
const Admin = lazy(() => import('../screens/Admin'));
const Demo = lazy(() => import('../components/Demo'));
const Dashboard = lazy(() => import('../screens/Dashboard/Dashboard'));
const BackTesting = lazy(() => import('../screens/BackTesting'));
const BudgetList = lazy(() => import('../screens/BudgetList/BudgetList'));
const ContractCreate = lazy(() => import('../screens/Contract/ContractCreate'));
const ContractDetails = lazy(() => import('../screens/Contract/ContractDetails'));
const ContractsList = lazy(() => import('../screens/Contract/ContractsList'));
const SimulationList = lazy(() => import('../screens/Simulation/SimulationList'));
const SimulationCreate = lazy(() => import('../screens/Simulation/SimulationCreate/SimulationCreate'));
const SimulationDetails = lazy(() => import('../screens/Simulation/SimulationDetails.js'));
const MarketTrendDetails = lazy(() => import('../screens/MarketTrend/MarketTrendDetails'));
const MarketTrendList = lazy(() => import('../screens/MarketTrend/MarketTrendList'));
const NewMarketTrend = lazy(() => import('../screens/MarketTrend/NewMarketTrend'));
const PageHelp = lazy(() => import('../screens/PageHelp/PageHelp'));
const PageHistoric = lazy(() => import('../screens/PageHistoric'));
const CountdownFreeTrial = lazy(() => import('../components/CountdownFreeTrial'));

const Routes = () => {
  const { pathname } = useLocation();
  const { userInfo } = useUserInfo();
  const { data: dataTimeTravel } = useGetTimeTravel();
  const { isLicenseOver } = useFreeLicenseOver();
  const portalAppUrl = useGetPortalApp();
  const [showDemo, setShowDemo] = useState(sessionStorage.getItem('travelInTime') === 'true');
  const showPoweredByYem = useMemo(() => isMultiAccess(userInfo), [userInfo]);

  // Used to get the correct dynamic data to display in <Breadcrumbs />
  const { isLoading: isContractsLoading } = useGetContracts();
  const { isLoading: isMarketTrendsLoading } = useGetMarketTrends(userInfo.language);
  const { isLoading: isListLoading } = useGetMultiAccessList();
  const { isLoading: isGroupListLoading } = useGetGroupList();

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      LogRocket.identify(`${userInfo._id}`, {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        email: userInfo.username,
        tenant: userInfo.tenant,
        role: userInfo.role,
      });
    }
  }, [userInfo]);

  const routes = useMemo(() => {
    const routesPerRoles = [];

    if (isManager(userInfo.role)) {
      routesPerRoles.push({ component: NewMarketTrend, path: Navigation.MARKET_TREND_NEW });
      routesPerRoles.push({ component: BudgetList, path: Navigation.BUDGET });
    } else {
      routesPerRoles.unshift({ component: Objectives, path: Navigation.OBJECTIVES });
      routesPerRoles.unshift({ component: Fixation, path: Navigation.FIXATION });
      routesPerRoles.unshift({ component: BlockFixation, path: Navigation.BLOCKFIXATION });
      routesPerRoles.unshift({ component: ContractDetails, path: Navigation.CONTRACTS_DETAILS });
      routesPerRoles.unshift({ component: ContractsList, path: Navigation.CONTRACTS });
      routesPerRoles.unshift({ component: ContractCreate, path: Navigation.CONTRACT_NEW });
      routesPerRoles.unshift({ component: SimulationDetails, path: Navigation.SIMULATION_DETAILS });
      routesPerRoles.unshift({ component: SimulationList, path: Navigation.SIMULATION });
      routesPerRoles.unshift({ component: SimulationCreate, path: Navigation.SIMULATION_NEW });
      routesPerRoles.push({ component: PageHistoric, path: Navigation.HISTORIC });
    }

    if (isMultiAccess(userInfo) && !isClient(userInfo.role)) {
      routesPerRoles.push({ component: WrapperMultiAccess, path: Navigation.MULTI_ACCESS, exact: false });
    }

    if (isClient(userInfo.role)) {
      routesPerRoles.unshift({ component: NewMarketTrend, path: Navigation.MARKET_TREND_NEW });
    }

    routesPerRoles.push(
      { component: ProductDetails, path: Navigation.PRODUCT_DETAILS },
      { component: MarketTrendList, path: Navigation.MARKET_TREND },
      { component: MarketTrendDetails, path: Navigation.MARKET_TREND_DETAILS },
      { component: PageHelp, path: Navigation.HELP },
      { component: BackTesting, path: [Navigation.BACKTESTING, Navigation.MARKET_TREND_BACKTESTING] },
      { component: Dashboard, path: Navigation.DASHBOARD },
    );

    return routesPerRoles;
  }, [userInfo]);

  if (isLicenseOver) {
    return window.location.assign(portalAppUrl);
  }

  if (isContractsLoading || isMarketTrendsLoading || isListLoading || isGroupListLoading) {
    return null;
  }

  return (
    <>
      <ReactHelmet />

      {!pathname.includes(Navigation.ADMIN) && (
        <Header isTimeTravelActive={dataTimeTravel?.enabled} setShowDemo={setShowDemo} showDemo={showDemo} />
      )}

      <Switch>
        <Route component={Admin} path={Navigation.ADMIN} />

        <ScrollProvider>
          <WrapperScroll>
            <Breadcrumb />

            <Space direction="vertical" size={40}>
              {(showDemo || (!isMultiAccess(userInfo) && isFreemium(userInfo))) && (
                <Space direction="vertical" size={40}>
                  {/* <LastFixation /> */}
                  {showDemo && <Demo dataTimeTravel={dataTimeTravel} />}
                  {!isMultiAccess(userInfo) && isFreemium(userInfo) && <CountdownFreeTrial />}
                </Space>
              )}

              <Suspense fallback=" ">
                <Row gutter={[0, 40]} justify="center">
                  <Col span={24}>
                    <Switch>
                      {routes.map(({ component, path, exact = true }) => (
                        <Route component={component} exact={exact} key={path} path={path} />
                      ))}
                      <Redirect from="*" to={Navigation.DASHBOARD} />
                    </Switch>
                  </Col>

                  {showPoweredByYem && (
                    <Col>
                      <PoweredByYem />
                    </Col>
                  )}
                </Row>
              </Suspense>
            </Space>
          </WrapperScroll>
        </ScrollProvider>
      </Switch>
    </>
  );
};

export default Routes;
