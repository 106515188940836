import { Steps as StepsAnt } from 'antd';
import React from 'react';
import styled, { css, ThemeContext } from 'styled-components';

import { TextColored } from '../../style/utils';

const { Step } = StepsAnt;

const CustomStep = styled(Step)`
  ${({ opaque }) => css`
    max-width: auto;
    opacity: ${opaque === 'true' ? '0.6' : '1'};
  `}
`;

const Steps = ({ className, steps = [], currentStep }) => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <div className={className}>
      <StepsAnt current={currentStep}>
        {steps.map((step, index) => {
          const iconWithColor = React.cloneElement(step.icon, {
            color: index <= currentStep ? themeContext.colors.baseColor : themeContext.colors.gray,
          });

          return (
            <CustomStep
              icon={iconWithColor}
              key={step.title}
              opaque={`${index < currentStep}`}
              title={<TextColored disabled={index > currentStep}>{step.title}</TextColored>}
            />
          );
        })}
      </StepsAnt>
    </div>
  );
};

export default styled(Steps)`
  ${({ theme: { colors } }) => css`
    padding: 0 12px 20px;
    width: 100%;
    border-bottom: 1px solid ${colors.gray};

    .ant-steps-item-title {
      max-width: 200px;
    }
    .ant-steps-item-container {
      display: flex;
      align-items: center;
      max-width: auto;
    }
  `}
`;
