export const Navigation = {
  DASHBOARD: '/',

  CONTRACTS: '/contracts',
  CONTRACT_NEW: '/contracts/new',
  CONTRACTS_DETAILS: '/contracts/:id',
  SIMULATION: '/simulation',
  SIMULATION_NEW: '/simulation/new',
  SIMULATION_DETAILS: '/simulation/:id',
  HISTORIC: '/contracts/:id/historic',
  PRODUCT_DETAILS: '/contracts/:id/:periodStart',
  FIXATION: '/contracts/:id/fixation',
  BLOCKFIXATION: '/contracts/:id/blockFixations',
  OBJECTIVES: '/contracts/:id/objectives',

  MARKET_TREND: '/market-trend',
  MARKET_TREND_NEW: '/market-trend/new',
  MARKET_TREND_DETAILS: '/market-trend/:id',
  MARKET_TREND_BACKTESTING: '/market-trend/:id/backtesting',

  BACKTESTING: '/backtesting',
  HELP: '/help',
  BUDGET: '/budget',

  MULTI_ACCESS: '/multi-access',
  MULTI_ACCESS_CREATE: '/multi-access/create',
  MULTI_ACCESS_DETAILS: '/multi-access/:userId',
  MULTI_ACCESS_EDIT: '/multi-access/:userId/edit',

  GROUP_LIST: '/multi-access/group',
  GROUP_CREATE: '/multi-access/group/create',
  GROUP_DETAILS: '/multi-access/group/:id',

  ADMIN: '/admin',
  ADMIN_CONTRACTS: 'admin/contracts',
  ADMIN_TRENDS: 'admin/trends',
  ADMIN_HISTORIC_MARKET: 'admin/historic-market',
  ADMIN_GAIN: 'admin/gain',
  ADMIN_BACKTESTING: 'admin/backtesting',
  ADMIN_MARKET_EVENT: 'admin/marketEvent',

  // PORTAL ROUTE
  ADMIN_PORTAL: '/admin',
  BASKET: '/basket',
  UPDATE_PASSWORD: '/update-password',
  TERM_CGU: '/term-cgu',
  TERM_RGPD: '/term-rgpd',

  LOGOUT: '/logout',
};
